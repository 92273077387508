import "./style.css";
import { FC } from "react";
import MainPage from "./components/mainPage/mainPage.component";
import About from "./components/about/about.component";
import { Box } from "@mui/material";
import Skeleton from "./components/skeleton/skeleton.component";
import Example from "./components/example/example.component";
import WhyUs from "./components/whyUs/whyUs.component";
import Contacts from "./components/contacts/contacts.component";
import Footer from "./components/footer/footer.component";

const Main: FC = () => {
    return (
        <>
            <MainPage />
            <Box className="component-container">
                <About />
                <Skeleton />
                <Example />
                <WhyUs />
                <Contacts />
                <Footer />
            </Box>
        </>
    );
};

export default Main;
