import "./style.css";
import { FC } from "react";
import { Box } from "@mui/material";

const FMS: FC = () => {
    return (
        <Box className="component-container">
            <Box className="container container-fms">
                <p className="title">FMS</p>
                <Box className="textbox">
                    <p>
                        <span className="glowing-text">
                            Система управления автопарком (Fleet Management
                            System, FMS)
                        </span>
                        <span>
                            {" "}
                            - это программное обеспечение, которое помогает
                            решать следующие задачи: ведение графиков
                            техобслуживания, страхования и ремонта, подготовка
                            отчетов и в целом обеспечение бесперебойной работы
                            автопарка.
                        </span>
                    </p>
                    <p>
                        <span>
                            {" "}
                            Владельцу современной транспортной компании важно не
                            только видеть, где находятся его машины, но и
                            понимать, сколько топлива они расходуют, иметь
                            возможность отслеживать другие параметры и влиять на
                            них, ставить задачи и контролировать их выполнение.
                            Являясь результатом эволюции систем мониторинга,
                            современные FMS позволяют управлять автопарком
                            буквально из одного окна
                        </span>
                    </p>
                </Box>
            </Box>
        </Box>
    );
};

export default FMS;
