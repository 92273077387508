import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { E164Number } from "libphonenumber-js/types";
import React, { FC } from "react";
import PhoneInput from "react-phone-number-input/input";
import "./style.css";

const Contacts: FC = () => {
    const [name, setName] = React.useState<string>("");
    const [phone, setPhone] = React.useState<E164Number>();
    const [email, setEmail] = React.useState<string>("");

    const clearForm = () => {
        setName("");
        setPhone(undefined);
        setEmail("");
    };

    return (
        <Box className="container container-contacts">
            <p className="title">Контакты</p>
            <Grid
                className="grid"
                container
                rowSpacing={3}
                columnSpacing={5}
                columns={{ xs: 1, md: 3 }}
            >
                <Grid item xs={1}>
                    <p className="contact-text">Оставьте свою заявку</p>
                    <Box className="contact-box leave-email">
                        <img src="img/envelope2.svg" alt="Письмо" />
                        <form className="form">
                            <p className="text">Как к вам обращаться?</p>
                            <input
                                type="text"
                                value={name}
                                placeholder="Имя"
                                onChange={(e) => setName(e.target.value)}
                            />
                            <p className="text">Ваш телефон</p>
                            <PhoneInput
                                country="RU"
                                value={phone}
                                onChange={setPhone}
                                placeholder="+79200359475"
                                international
                                withCountryCallingCode
                            />
                            <p className="text">Ваша почта</p>
                            <input
                                type="email"
                                value={email}
                                placeholder="example@mail.com"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Button
                                variant="outlined"
                                className="contact-button"
                                onClick={clearForm}
                            >
                                Напишите мне!
                            </Button>
                        </form>
                    </Box>
                </Grid>
                <Grid item xs={1} md={2}>
                    <p className="contact-text">Или свяжитесь с нами сами!</p>
                    <Box className="contact-box visit-us">
                        <Box className="left-side">
                            <Box className="icons-box">
                                <img src="img/building.svg" alt="Здание" />
                                <img src="img/telephone.svg" alt="Телефон" />
                            </Box>
                            <Box className="textblock">
                                <p className="contact-text">Наш адрес</p>
                                <p className="text">
                                    г. Нижний Новгород, Южное шоссе, 16В, офис
                                    321, 603142
                                </p>
                                <p className="contact-text">Наш телефон</p>
                                <p className="text">
                                    <a href="tel:+79308181806">
                                        +7 (930) 818-18-06
                                    </a>
                                </p>
                                <p className="contact-text">Наша почта</p>
                                <p className="text">development@it-irsa.ru</p>
                            </Box>
                        </Box>
                        <Box className="right-side">
                            <iframe
                                title="yandex-maps"
                                src="https://yandex.ru/map-widget/v1/?um=constructor%3Afccf51238c78b95be3fd5a7804f8fb401f35bb5880119c6507cbbdb2ebe92004&amp;source=constructor"
                                width="100%"
                                height="100%"
                            ></iframe>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Contacts;
