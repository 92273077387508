import { FC } from 'react';
import { Box } from '@mui/material';
import './style.css';

const Footer: FC = () => {
	return (
		<Box className="footer-container">
			<Box className="footer-info text-primary">
				<p>ООО "Ирса". Все права защищены.</p>
				<p>ИНН: 5256206715</p>
				<p>ОКВЭД: 1.01, 1.02</p>
			</Box>
		</Box>
	);
};

export default Footer;
